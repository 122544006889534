import axios from "axios";
import authHeader from "./auth-header";
const API_URL  = "https://driverappbackend.onrender.com/api/fetchDrivers/"


const getDriver = async (driverPhone) => {
    try {
        const response = await axios.get(`${API_URL}${driverPhone}/`, {}, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}



const driverService = {

    getDriver

};

export default driverService;