import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import {
    CalendarMonthOutlined,
    SupportAgentOutlined,
    ElectricRickshawOutlined,
    PaymentOutlined
} from '@mui/icons-material/';
import Paper from '@mui/material/Paper';
import Home from "../Page Components/Home/Home";
import SubscriptionComponent from "../Page Components/Subscription/Subscription";
import SupportComponent from "../Page Components/Support/Support";
import PaymentComponent from "../Page Components/Payment/Payment";
import {Badge, styled} from "@mui/material";
import subscriptionService from "../../Services/subscription.service";

const TabBarButton = styled(BottomNavigationAction)({
    color: 'rgba(255,255,255,0.59)',
    '&.Mui-selected': {
        color: '#ffffff',
    },
});


export default function FixedBottomNavigation() {
    const [value, setValue] = React.useState(0);
    const [badgeContent, setBadgeContent] = React.useState(0);
    const [isPaymentTabEnabled, setIsPaymentTabEnabled] = React.useState(false);
    const userId = localStorage.getItem('user_id');

    React.useEffect(() => {
        // subscriptionService.getPaymentStatus(userId)
        //     .then(response => {
        //         if (response.payment_status === false) {
        //             setBadgeContent(0);
        //         } else {
        //             setBadgeContent(1);
        //         }
        //     })
        //     .catch(error => {
        //         console.error(error);
        //     });

        subscriptionService.getNextWeekRideCount(userId)
            .then(response => {
                if (response > 0) {
                    setIsPaymentTabEnabled(true);
                    setBadgeContent(1);

                } else {
                    setIsPaymentTabEnabled(false);
                    setBadgeContent(0);
                }
            })
            .catch(error => {
                console.error(error);
            });
    }, [userId]);

    return(
        <div>
            {value === 0 && <Home/>}
            {value === 1 && <SubscriptionComponent/>}
            {value === 2 && <PaymentComponent/>}
            {value === 3 && <SupportComponent/>}

            <div style={{height: '65px'}}></div>

            <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0}} elevation={4}>
                <BottomNavigation
                    showLabels
                    value={value}
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    sx={{
                        bgcolor: '#132961',
                        "& .Mui-selected, .Mui-selected > svg": {
                            color: "#ffffff"
                        },
                        height: '65px'
                    }}
                >
                    <TabBarButton label="Schedule"
                                  icon={<ElectricRickshawOutlined fill='rgba(255,255,255,0.59)'/>}
                                  sx={{color: '#78A6E6'}}/>
                    <TabBarButton label="Subscription"
                                  icon={<CalendarMonthOutlined fill='rgba(255,255,255,0.59)'/>}
                                  sx={{color: '#78A6E6'}}/>
                    <TabBarButton label="Payment"
                                  icon={
                                      <Badge badgeContent={badgeContent} color="secondary">
                                          <PaymentOutlined fill='rgba(255,255,255,0.59)'/>
                                      </Badge>
                                  }
                                  disabled={badgeContent === 0 || !isPaymentTabEnabled}
                                  sx={{color: (badgeContent === 0 || !isPaymentTabEnabled) ? 'grey' : '#78A6E6'}}/>
                    <TabBarButton label="Support" icon={<SupportAgentOutlined fill='rgba(255,255,255,0.59)'/>}
                                  sx={{color: '#78A6E6'}}/>
                </BottomNavigation>
            </Paper>
        </div>
    );
}