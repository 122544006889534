import axios from "axios";
import authHeader from "./auth-header";
const API_URL  = "https://fastapi-backend-nxx4.onrender.com/"

const getAddress = async () => {
    try {
        const response = await axios.get(API_URL + "get-addresses", {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const addressService = {
    getAddress

};

export default addressService;