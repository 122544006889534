import React, {useState, useEffect, useCallback} from 'react';
import {Button} from 'antd'
import {Button as MuiButton, Typography} from '@mui/material';
import './Home.css'
import UpcomingRidesComponent from "../Upcomming Rides/UpcommingRides";
import PastRidesComponent from "../Past Rides/PastRides";
import HeaderComponent from "../../Header/Header";
import {ArrowBackIosOutlined, ArrowForwardIosOutlined} from "@mui/icons-material";
import {useLocation, useNavigate} from "react-router-dom";

export default function Home() {
    const [activeButton, setActiveButton] = useState('upcoming');
    const [key, setKey] = useState(0); // Add a key state
    const [currentDate, setCurrentDate] = useState('');
    const [currentWeek, setCurrentWeek] = useState(0);
    const [toDate, setToDate] = useState('');
    const [weekOffset, setWeekOffset] = useState(0); // Add a weekOffset state
    const [propStartDate, setPropStartDate] = useState(new Date());
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const queryOffset = queryParams.get('offset');



    const updateWeek =  useCallback((offset) => {
        // Prevent offset from going below 0 or above 1
        if (offset < 0 || offset > 1) {
            return;
        }

        setWeekOffset(offset); // Update the weekOffset state

        const date = new Date();
        date.setDate(date.getDate() + (offset * 7));
        const startOfWeek = new Date(date);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1); // Get the start of the week (Monday)
        const startDate= startOfWeek.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })
        setCurrentDate(startDate); // Set the current date to the start of the week
        setPropStartDate(new Date(startOfWeek));

        console.log({startDate, startOfWeek})
        const start = new Date(date.getFullYear(), 0, 0);
        const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
        const oneDay = 1000 * 60 * 60 * 24;
        const day = Math.floor(diff / oneDay);
        setCurrentWeek(Math.ceil(day / 7)); // Set the current week of the year

        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 6); // Get the end of the week (Sunday)

        const endDate= startOfWeek.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' })

        setToDate(endDate); // Set the toDate state variable
        navigate(`?offset=${offset}`);
    },[navigate])

    useEffect(() => {
        if(queryOffset==='1')
            updateWeek(queryOffset);
        else
            updateWeek(0)
    },[queryOffset, updateWeek]);

    if(!localStorage.getItem("access_token")){
        window.location.href = "/"
    }
    return (
        <div className="home-container">
            <HeaderComponent></HeaderComponent>

            <div className="rides-tab" > {/* Add the key here */}
                <div className="rides-button-conatiner" key={key}>
                    <Button
                        shape="round"
                        type="primary"
                        className={`rides-button ${activeButton === 'upcoming' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveButton('upcoming');
                            setKey(prevKey => prevKey + 1); // Change the key every time a button is clicked
                        }}
                    >
                        Upcoming Rides
                    </Button>
                    <Button
                        shape="round"
                        type="primary"
                        className={`rides-button ${activeButton === 'past' ? 'active' : ''}`}
                        onClick={() => {
                            setActiveButton('past');
                            setKey(prevKey => prevKey + 1); // Change the key every time a button is clicked
                        }}
                    >
                        Past Rides
                    </Button>
                </div>

            </div>
            {activeButton !== 'past' && (
                <div className="date-view">
                    <MuiButton
                        disabled={weekOffset === 0}
                        onClick={() => updateWeek(weekOffset - 1)}>
                        <ArrowBackIosOutlined/>
                    </MuiButton>

                    <Typography sx={{color: '#132961', fontSize: '16px'}} className="date-view-data">
                        Week {currentWeek}: {currentDate} - {toDate}
                    </Typography>

                    <MuiButton
                        disabled={parseInt(''+weekOffset, 10) === 1}
                        onClick={() => updateWeek(1)}>
                        <ArrowForwardIosOutlined/>
                    </MuiButton>
                </div>
            )}
            <div className="ride-details">
                {activeButton === 'past' ? (
                    <PastRidesComponent/> // Render the PastRidesComponent when activeButton is 'past'
                ) : (
                    <UpcomingRidesComponent offset={queryOffset} startDate={propStartDate} week={currentWeek}/> // Render the UpcommingRidesComponent when activeButton is not 'past'
                )}
            </div>
        </div>
    )
}