import axios from "axios";
import authHeader from "./auth-header";
const API_URL  = "https://fastapi-backend-nxx4.onrender.com/"

const addRides = (ride) => {
    return axios.post(API_URL + "create_user_subscription_and_rides", ride, {
        headers: authHeader()
    })
        .then((response) => {
            return response.data;
        });
}
const getRides = async () => {
    try {
        const response = await axios.get(API_URL + "get-user-subscriptions-and-rides", {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const cancelRide = async (rideId) => {
    try {
        const response = await axios.put(`${API_URL}cancel-ride/${rideId}`, {}, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const rescheduleRide = async (rideId, newDate) => {
    try {
        const response = await axios.put(`${API_URL}request-reschedule-ride/`, {ride_id:rideId,new_datetime:newDate}, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const getPastRidesDetails = async (userID,subscriptionPlan) => {
    try {
        const response = await axios.get(`${API_URL}get-last-subscription-details?user_id=${userID}&subscription_plan=${subscriptionPlan}`, {}, {
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const getNextWeekRideCount = async (userID) => {
    try {
        const response = await axios.get(`${API_URL}Latest_subscription_ride_count/?user_id=${userID}`, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const getPaymentStatus = async (userID) => {
    try {
        const response = await axios.get(`${API_URL}payment_status_of_latest_Subs/?user_id=${userID}`,  {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }

}
const getPricePerTrip = async () => {
    try {
        let phone_number = localStorage.getItem("phone_number");
        const response = await axios.get(`${API_URL}get-price/${phone_number}`,  {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const getRidesCountByUserID = async (userID,subscriptionPlan) => {
    try {
        const response = await axios.get(`${API_URL}getRidesCountByUser/${userID}`, {}, {
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const getLatestSubscriptionId = async () => {
    const userId = localStorage.getItem("user_id"); // Fetch user id from local storage

    try {
        const response = await axios.get(`${API_URL}fetch_latest_subscription_id_per_customer/?user_id=${userId}`, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
    }
}
const updatePaymentBySubId = async (subs_id,subs_cost) => {
    const userId = localStorage.getItem("user_id");
    try {

        const response = await axios.put(`${API_URL}update_payment_status/?user_id=${userId}`, {subs_id:subs_id,subs_cost:subs_cost}, {
            headers: authHeader()
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
        // Handle the error based on your application's requirements
    }
}
const subscriptionService = {
    addRides,
    getRides,
    cancelRide,
    rescheduleRide,
    getPastRidesDetails,
    getNextWeekRideCount,
    getPaymentStatus,
    getPricePerTrip,
    getRidesCountByUserID,
    updatePaymentBySubId,
    getLatestSubscriptionId

};

export default subscriptionService;