import { Button, Card, CardActionArea, Typography} from "@mui/material";
import {RidesHeaderComponent} from "../../Header/Header";
import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    ArrowForwardOutlined,
    BusinessOutlined,
    EastOutlined, ErrorOutlineOutlined,
    FitnessCenterOutlined,
    HomeOutlined, SchoolOutlined
} from "@mui/icons-material";
import './Reschedule.css';
import {TimePicker} from "antd";
import Swal from "sweetalert2";
import subscriptionService from "../../../Services/subscription.service";

export default function RescheduleComponent(){

    const location = useLocation();
    const ride = location.state.ride;
    const index = location.state.index;
    const navigate = useNavigate();
    const [rescheduleTime, setRescheduleTime] = React.useState(null);

    const handleTimeChange = (time) => {
        // Create a new Date object from ride.ride_date_time
        const rideDateTime = new Date(ride.ride_date_time);

        // Extract the year, month, and day
        const year = rideDateTime.getFullYear();
        // Extract the month and pad it with zeros if it's less than 10
        const month = String(rideDateTime.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript

        // Extract the day and pad it with zeros if it's less than 10
        const day = String(rideDateTime.getDate()).padStart(2, '0');
        // Format the rescheduledDateTime to the desired format
        const formattedTime = time ? time.format('HH:mm:ss') : null;
        const datetime =`${year}-${month}-${day} ${formattedTime}.000`;

        setRescheduleTime(datetime);
    };
    const handleSubmit = () => {
        const currTime= new Date(ride.ride_date_time).toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit'
            });
        const rescheduledTime = new Date(rescheduleTime).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        });
        if(rescheduleTime === null){
            Swal.fire({
                icon: 'error',
                title: 'Select Time',
                text: 'Please select a time to reschedule the ride',
            })
            return;
        }


        Swal.fire({
            title: 'Reschedule Ride',
            text: `You have selected to Reschedule the ride from ${currTime} to ${rescheduledTime} `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, submit it!'
        }).then((result) => {
            if (result.isConfirmed) {
                console.log(rescheduleTime);
                subscriptionService.rescheduleRide(ride.id, rescheduleTime).then((response) => {
                    console.log(response);
                        Swal.fire(
                            'Submitted!',
                            'Your request has been submitted.',
                            'success'
                        )
                        navigate(`/home`);
                })
            }
        })
    }
    const getIcon = (iconName) => {
        switch(iconName.toLowerCase()) {
            case 'home':
                return <HomeOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'office':
                return <BusinessOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'gym':
                return <FitnessCenterOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'school':
                return <SchoolOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            // Add more cases here if you have more icons
            default:
                return <ErrorOutlineOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
        }
    };

    return(
        <div className="home-container">
            <RidesHeaderComponent rides={"true"} link={`/ride-details/${ride.id}`} ride={ride} index={index} title={"Reschedule Ride"}/>
            <div className="reschedule-container">
                <div className="rides-detail-card-container">
                    <Card sx={{p: 2}} className="ride-detail-card">
                        <Typography sx={{color: "#132961", fontSize: 20}}>
                            Ride Scheduled for
                        </Typography>
                        <Typography sx={{color: "#132961", fontSize: 30, fontWeight: 700}}>
                            {new Date(ride.ride_date_time).toLocaleTimeString('en-US', {
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </Typography>
                        <Typography sx={{color: "#132961", fontSize: 23, fontWeight: 400}}>
                            {new Date(ride.ride_date_time).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: 'short',
                                weekday: 'long'
                            })}
                        </Typography>
                        <div className="ride-detail-location-icon-container">
                            <div className="ride-detail-trip-address-card-title-container" style={{paddingLeft: "0px"}}>
                                {getIcon(ride.pickup_address_type)}
                                <Typography className="ride-detail-trip-address-card-title-text"
                                            sx={{fontSize: "15px"}}>
                                    {ride.pickup_address_type}
                                </Typography>
                            </div>
                            <div className="ride-detail-card-data-direction-icon">
                                <EastOutlined sx={{color: "#132961"}}></EastOutlined>
                            </div>
                            <div className="ride-detail-trip-address-card-title-container no-border">
                                {getIcon(ride.drop_address_type)}
                                <Typography className="ride-detail-trip-address-card-title-text"
                                            sx={{fontSize: "15px"}}>
                                    {ride.drop_address_type}
                                </Typography>
                            </div>
                        </div>
                    </Card>
                </div>
                <div className="policy-card-container">
                    <CardActionArea
                        onClick={() => navigate(`/policy`, {state: {ride: ride,returnTo:'reschedule', index: location.state.index}})}
                        key={ride.id}>
                        <Card sx={{p: 2}} className="policy-card">
                            <Typography>
                                View Reschedule & Cancel Ride Policy
                            </Typography>
                            <ArrowForwardOutlined sx={{color: "#0049AF"}} className="ride-trip-icon"/>
                        </Card>
                    </CardActionArea>
                </div>

                <div className="reschedule-disclaimer-text">
                    <Typography sx={{mt:2}}>
                        Reschedule Window remains opens 2 hrs to 30 min before the ride
                    </Typography>
                </div>
                <div className="reschedule-timepicker-container">
                    <TimePicker
                        inputReadOnly={true}
                        changeOnBlur={true}
                        minuteStep={5}
                        onChange={(time) =>
                            handleTimeChange(time)}
                        format='hh:mm a'
                        placeholder="Select Time"
                        style={{ width: '100%'}}
                    />
                </div>
                <div className="reschedule-button-container">
                    <Button variant="contained"  fullWidth={true} className="subscription-button" onClick={handleSubmit}>
                        Request
                    </Button>
                </div>
            </div>
        </div>
    )
}