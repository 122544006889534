import axios from "axios";
import authHeader from "./auth-header";
// require('dotenv').config();
const API_URL  = "https://fastapi-backend-nxx4.onrender.com/"

const generateOTP = (phone_number) => {
    return axios.post(API_URL + "auth/generate-otp?phone_number="+phone_number, {
        params: {
            phone_number: phone_number,

        }
    })
        .then((response) => {
            return response.data;
        });

};

const verifyOTP = (phone_number, otp) => {
    return axios.post(API_URL + "auth/verify-otp?phone_number="+phone_number+"&otp="+otp, {
        params: {
            phone_number,
            otp,
        }
    })
        .then((response) => {
            if(response.data.authToken){
                localStorage.setItem("user", JSON.stringify(response.data.id));
                localStorage.setItem("authToken", JSON.stringify(response.data.authToken));
                localStorage.setItem("loggedIn", JSON.stringify(true));
            }
            return response.data;
        });
}
const get_user_details = async () => {
    const config = {
        headers: authHeader()
    };

    try {
        const response = await axios.get(API_URL + "get-user-details", config);
        return response.data;
    } catch (error) {
        console.log(error.response.status);
        if(error.response.status === 401){
            logout();
        }
        if(error.response.status === 429){
            return error.response.status;
        }

        // Handle the error based on your application's requirements
    }
}


const logout = () => {
    localStorage.clear();

};
const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

const authService = {
    generateOTP,
    verifyOTP,
    get_user_details,
    logout,
    getCurrentUser,
};

export default authService;