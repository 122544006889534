import React from 'react';
import './Card.css';
import {Card, Typography} from "@mui/material";
import {
    ArrowForwardOutlined,
    BusinessOutlined,
    EastOutlined, ErrorOutlineOutlined,
    FitnessCenterOutlined,
    HomeOutlined, SchoolOutlined
} from "@mui/icons-material"; // Import the CSS file

const CardComponent = (props) => {
    const getIcon = (iconName) => {
        switch(iconName.toLowerCase()) {
            case 'home':
                return <HomeOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'office':
                return <BusinessOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'gym':
                return <FitnessCenterOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'school':
                return <SchoolOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            // Add more cases here if you have more icons
            default:
                return <ErrorOutlineOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
        }
    };
    const rideStatusColors = {
        'cancelled': '#FF3141',
        'rescheduled': '#FF8F1F',
        'completed': '#06b073',
        'reject': '#FF3141',
        'approved': '#06b073',
        'rejected': '#FF3141'
        // Add more status-color pairs here if needed
    };
    const rideStatusTexts = {
        'cancelled': 'The Ride is Cancelled',
        'rescheduled': 'Reschedule requested.(ETA-15 Mins to Approve/Reject)',
        'completed': 'The Ride Is Completed',
        'rejected': 'The Ride was not taken'
        // Add more status-text pairs here if needed
    }
    // let gradientDirection = props.tripNumber === 2 ? 'to left' : 'to right';
    let gradientColor = rideStatusColors[props.rideStatus] || '#1f6fc5'; // Default color is blue
    // let borderImageSource = `linear-gradient(${gradientDirection}, ${gradientColor}, #FFF)`;

    let rescheduleStatus = props.rescheduleStatus;
    let rescheduleStatusText='';
    if (rescheduleStatus === 'approved') {
        rescheduleStatusText = 'Ride rescheduled';
    }
    else if (rescheduleStatus === 'reject') {
        rescheduleStatusText = 'Reschedule request rejected due to slot unavailability';
    }
    // console.log(borderImageSource);


    return (
        <div className="card-wrapper">
            <Card className="gradient-border" style={{ borderColor: gradientColor }} sx={{m: 2, p: 2, pr: 0, mr: 0, ml: 0}} >
                <div className="card-data-container">
                    <div className="card-data-upper">
                        <div className="trip-address-card-title-container add-margin-upper">
                            {getIcon(props.pickupAddressType)}
                            <Typography className="trip-address-card-title-text" sx={{fontSize: "15px"}}>
                                {props.pickupAddressType}
                            </Typography>
                        </div>
                        <div className="card-data-direction-icon add-margin-upper">
                            <EastOutlined></EastOutlined>
                        </div>
                        <div className="trip-address-card-title-container no-border">
                            {getIcon(props.dropAddressType)}
                            <Typography className="trip-address-card-title-text" sx={{fontSize: "15px"}}>
                                {props.dropAddressType}
                            </Typography>
                        </div>
                    </div>
                    <div className="card-data-divider"></div>
                    <div className="card-data-lower">
                        <div className="card-data-lower-data">
                            <p className="ride-time-data">
                                {props.rideTime}
                            </p>
                        </div>
                        <div className="card-data-lower-data">
                            <p className="ride-vehicle-no">
                                {props.vehicleNumber}
                            </p>
                        </div>
                        <div className="card-data-lower-data">
                            <div className="ride-trip-number-container">
                                <Typography className="ride-trip-number">
                                    Trip {props.tripNumber}
                                </Typography>
                                <ArrowForwardOutlined sx={{color: "#0049AF"}} className="ride-trip-icon"/>
                            </div>
                        </div>
                    </div>
                </div>
                {props.rideStatus && (
                    props.rideStatus.toLowerCase() === 'upcoming' ?
                        <Typography sx={{color: rideStatusColors[props.rescheduleStatus], fontSize: '14px', textAlign: 'center', marginTop: '10px'}}>
                            {rescheduleStatusText}
                        </Typography>
                        :
                        <Typography sx={{color: rideStatusColors[props.rideStatus], fontSize: '14px', textAlign: 'center', marginTop: '10px'}}>
                            {rideStatusTexts[props.rideStatus]}
                        </Typography>
                )}

            </Card>
        </div>
    );
};

export default CardComponent;