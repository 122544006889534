import HeaderComponent from "../../Header/Header";
import {
    Button,
    Card, Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import './Payment.css'
import { usePastRidesDetails } from "../../../Provider/PaymentDetails";
import CloseIcon from "@mui/icons-material/Close";
import payment_qr from '../../../Assets/payment_qr.png'
import SubscriptionService from "../../../Services/subscription.service";
import CopyToClipboardButton from "../../CopyButton/CopyButton";


export default function PaymentComponent() {
    const user_id = localStorage.getItem('user_id');
    const dropAddressType = localStorage.getItem('dropAddressType');

    const [pricePerTrip, setPricePerTrip] = useState(0);


    useEffect(() => {
        // Call the getPricePerTrip function when the component mounts
        SubscriptionService.getPricePerTrip()
            .then(data => {
                // Update the pricePerTrip state variable with the returned value
                setPricePerTrip(data.price_per_trip);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    const [nextWeekRideCount, setNextWeekRideCount] = useState(0);

    useEffect(() => {
        SubscriptionService.getNextWeekRideCount(user_id)
            .then(response => {
                console.log(response)
                setNextWeekRideCount(response);
            })
            .catch(error => {
                console.error(error);
            });
    }, [user_id]);
    const { carryForward } = usePastRidesDetails(user_id, dropAddressType,nextWeekRideCount);

    console.log({nextWeekRideCount});
    const [subscriptionId, setSubscriptionId] = useState(null);
    useEffect(() => {
        SubscriptionService.getLatestSubscriptionId()
            .then(data => {
                setSubscriptionId(data.subscription_id);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (subscriptionId && pricePerTrip) {
            const subscriptionCost = pricePerTrip * (nextWeekRideCount - carryForward);
            SubscriptionService.updatePaymentBySubId(subscriptionId, subscriptionCost)
                .then(data => {
                    console.log(data); // Handle the response based on your application's requirements
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [carryForward,nextWeekRideCount,subscriptionId, pricePerTrip]);



    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);

    };
    const handleClose = () => {
        setOpen(false);
    };
    const currentDate = new Date();

// Get the current week number
    const currentWeekNumber = Math.ceil((currentDate - new Date(currentDate.getFullYear(),0,1)) / 604800000);

// Get the next week number
    const nextWeekNumber = currentWeekNumber + 1;
    return (
        <div className="home-container">
            <HeaderComponent/>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                fullWidth={true}
                maxWidth={'lg'}
            >
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon/>
                    </IconButton>
                </div>
                <DialogTitle sx={{m: 0, p: 2}}
                             id="customized-dialog-title">
                    <Typography sx={{textAlign:'center', fontSize:'25px'}}>
                        Your Subscription Amount for Week {nextWeekNumber} is
                    </Typography>
                    <Typography sx={{textAlign:'center', fontSize:'50px'}}>
                        Rs {pricePerTrip*(nextWeekRideCount-carryForward)}
                    </Typography>
                    <Typography sx={{textAlign:'center'}}>
                        Please scan the below QR code on any Payment App (Gpay, Paytm, PhonePe) to pay
                        OR
                        Use the UPI ID provided.
                    </Typography>
                </DialogTitle>

                <DialogContent dividers>
                    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img  src={payment_qr} alt="payment_qr" className="payment-page-qr-image"/>
                    </div>
                    <Card>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography>
                                <a href={`upi://pay?pa=8867712288@paytm&am=${pricePerTrip*(nextWeekRideCount-carryForward)}`}>
                                    8867712288@paytm
                                </a>
                            </Typography>
                            <CopyToClipboardButton
                                value="8867712288@paytm"
                                message="UPI ID Copied to Clipboard"
                            />
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Typography>
                                <a href={`upi://pay?pa=telkestech@okhdfcbank&am=${pricePerTrip*(nextWeekRideCount-carryForward)}`}>
                                    telkestech@okhdfcbank
                                </a>
                            </Typography>
                            <CopyToClipboardButton
                                value="telkestech@okhdfcbank"

                                message="UPI ID Copied to Clipboard"
                            />
                        </div>
                    </Card>

                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
            <div className="payment-page-container">
                <Card sx={{p: 2}}>
                    <div className="payment-page-data-container">
                        <Typography>
                        Weekly Total Rides Scheduled:
                        </Typography>
                        <Typography>
                            {nextWeekRideCount}
                        </Typography>
                    </div>
                    <div className="payment-page-data-container">
                        <Typography>
                            Carry Forward:
                        </Typography>
                        <Typography>
                            {carryForward}
                        </Typography>
                    </div>
                    <div className="payment-page-data-container">
                        <Typography>
                            Total Rides Charged:
                        </Typography>
                        <Typography>
                            {nextWeekRideCount-carryForward}
                        </Typography>
                    </div>
                    <div className="payment-page-data-container">
                        <Typography>
                            Per Trip Cost:
                        </Typography>
                        <Typography>
                            {pricePerTrip}
                        </Typography>
                    </div>
                    <div className="payment-page-data-container">
                        <Typography>
                            Subscription Cost:
                        </Typography>
                        <Typography>
                            {pricePerTrip*(nextWeekRideCount-carryForward)}
                        </Typography>
                    </div>

                </Card>
                <div className="subscription-button-container">
                    <Button variant="contained" onClick={handleClickOpen} fullWidth={true} className="subscription-button"
                             >Pay</Button>
                </div>
            </div>
        </div>
    )
}