import {Card, Typography} from "@mui/material";
import React from "react";

const TnCCard = () => (
    <Card sx={{
        p: 2,
        mb: 2,
        mt:2,
        height: '200px',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
            width: '10px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            borderRadius: '20px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,.2)',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,.05)',
        }
    }} elevation={6}>
        <Typography variant="h7"><strong>Please read the TnC given before booking:</strong></Typography>
        <Typography variant="body1">
            1. Above trip price is for one seat only as all our rides are <strong>shared rides</strong>
        </Typography>
        <Typography variant="body1">
            2. Once we find a <strong>co-passenger</strong> matching your route we allocate the remaining seat post-informing you
        </Typography>
        <Typography variant="body1">
            3. <strong>Cancellation policy</strong>
            <ul style={{ marginTop: 0,marginBottom:0 }}>
                <li>In case you cancel rides via the app</li>
                <li>1 free carry-forward for &gt;4 booked rides</li>
                <li>2 free carry-forward for &gt;8 booked rides</li>
            </ul>
        </Typography>
        <Typography variant="body1">
            4. <strong>Reschedule</strong> will be done free of charge only basis availability
        </Typography>
        <Typography variant="body1">
            5. All payments for the current week need to be completed before <strong>Monday EOD</strong>.
        </Typography>
    </Card>
);

export default TnCCard;