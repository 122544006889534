import axios from "axios";
const API_URL  = "https://loconav-apis.onrender.com/"

const getETA = async (vehicleNo, lat, long) => {
    try {
        const headers = {
            'vehicle-number': vehicleNo,
            'lat': lat,
            'lon': long
        };

        const response = await axios.get(`${API_URL}Customer_slots/estimated_time_vh_number/`, { headers });
        return response.data;
    } catch (error) {
        console.error(error);
        return "SERVER ERROR";
    }
}





const loconavService = {
    getETA

};

export default loconavService;