import './Support.css';
import authService from "../../../Services/auth.service";
import HeaderComponent from "../../Header/Header";
import React, {useEffect, useState} from "react";
import {Card, Typography ,Button } from "@mui/material";
import {ArrowForwardOutlined, CallOutlined, Instagram, LinkedIn, WhatsApp} from "@mui/icons-material";
import addressService from "../../../Services/address.service";
export default function SupportComponent(){
    function logout(){
        authService.logout();
        window.location.href = "/"
    }

    const [pickupAddress, setPickupAddress] = useState('Loading...');
    const [dropAddress, setDropAddress] = useState('Loading...');
    useEffect(() => {
        const fetchAddresses = async () => {
            try {
                let address;
                const cachedAddresses = localStorage.getItem('addresses');
                const addressTimestamp = localStorage.getItem('addressTimestamp');

                if (cachedAddresses && addressTimestamp) {
                    const currentTime = new Date().getTime();
                    const timeDifference = (currentTime - addressTimestamp) / 1000 / 60; // Convert to minutes

                    if (timeDifference < 1) {
                        // If the cached address is less than 1 minute old, use it
                        address = JSON.parse(cachedAddresses);
                    }
                }

                if (!address) {
                    // If there's no cached address or it's more than 1 minute old, fetch a new one
                    address = await addressService.getAddress();

                    // Store the new address and the current addressTimestamp in local storage
                    localStorage.setItem('addresses', JSON.stringify(address));
                    localStorage.setItem('addressTimestamp', new Date().getTime().toString());
                }
                if(address.length>0){
                    setPickupAddress(address.find(item => item.address_type === 'Home')?.address || 'Address not found');
                    setDropAddress(address.find(item => !(item.address_type === 'Home'))?.address || 'Address not found');
                }
                else{
                    setPickupAddress('No Address Found');
                    setDropAddress('No Address Found');
                }

            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchAddresses();
        // Refresh the address every 1 minute
        const intervalId = setInterval(fetchAddresses, 60 * 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);
    let phoneNumber;
    function getUserDetails(){
        try {
            const userDetails = JSON.parse(localStorage.getItem('userDetails'));
            phoneNumber = userDetails?.phone_number;
        } catch (error) {
            console.error('Error parsing userDetails from localStorage:', error);
            // Handle the error as needed
        }
    }
    getUserDetails();

    return (
        <div>
            <HeaderComponent/>


            <div className="support-container">
                <div className="account-details-container">
                    <Card sx={{p: 2, width:"90%"}}>
                        <div className="account-details-title">
                            Your Mobile Number
                        </div>
                        <div className="account-details-data">
                            {phoneNumber}
                        </div>

                        <div className="account-details-title">
                            Home Address
                        </div>
                        <div className="account-details-data">
                            {pickupAddress}

                        </div>
                        <div className="account-details-title">
                            Drop (School/Office) Address;
                        </div>
                        <div className="account-details-data">
                            {dropAddress}
                        </div>
                    </Card>
                </div>
                <div className="call-card-container">
                    <Card sx={{p: 2}} className="call-card">
                        <div className="card-upper-container">
                            <Typography  sx={{ fontSize: '24px' }} className="card-call-number">
                                8867712288
                            </Typography>
                            <div className="card-call-button">
                                <a href="tel:8867712288" style={{textDecoration: 'none'}}>
                                    <Button variant={"contained"} startIcon={<CallOutlined/>}>Call</Button>
                                </a>
                            </div>
                        </div>
                        <div className="card-lower-container">
                            <div className="card-call-text-container">
                                <Typography className="card-call-text">
                                    Call us for any queries
                                </Typography>

                            </div>
                        </div>
                    </Card>
                </div>
                <div className="feedback-card-container">
                    <Card sx={{p: 2}} className="feedback-card">
                        <Typography sx={{fontSize:20}} className="feedback-card-text">Feedback Form</Typography>
                        <div className="feedback-icon-arrow">
                            <ArrowForwardOutlined></ArrowForwardOutlined>
                        </div>
                    </Card>
                </div>
                <div className="follow-us-container">
                    <Card sx={{p:2}} className="follow-us-card">
                        <Typography className="follow-us-text">Follow us on</Typography>
                        <div className="follow-us-icons">
                            <div className="follow-us-icon">
                                <a href="https://www.instagram.com/mypickup.in" target="_blank"
                                   rel="noopener noreferrer">
                                    <Instagram sx={{fontSize: 40, color: '#E1306C'}}/>
                                </a>
                            </div>
                            <div className="follow-us-icon">
                                <a href="https://www.linkedin.com/company/mypickup/" target="_blank"
                                   rel="noopener noreferrer">
                                    <LinkedIn sx={{fontSize: 40, color: '#0A66C2'}}/>
                                </a>
                            </div>
                            <div className="follow-us-icon">
                                <a href="https://wa.me/8867712288" target="_blank" rel="noopener noreferrer">
                                    <WhatsApp sx={{fontSize: 40, color: '#25D366'}}/>
                                </a>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
            <div className="logout-button-container">
                <Button className="logout-button" onClick={logout} color="error" variant="outlined">Logout</Button>
            </div>
        </div>
    );
}