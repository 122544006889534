import "./Home.css"
import FixedBottomNavigation from "../Components/BottomNav/Bottomnav";
import authService from "../Services/auth.service";
import {useEffect} from "react";
export default function HomePage() {


    useEffect(() => {
        authService.get_user_details().then(data => {
            if(data!==429){
                console.log(data);
                localStorage.setItem('userDetails', JSON.stringify(data));
                if (data && data.id) {
                    localStorage.setItem('user_id', data.id);
                  } else {
                    console.error('Error: data is missing an id');
                    // Handle the error case as needed
                  }
            }
            else{
                console.log("API ERROR");
            }

        });
    }, []);
    return (
            <FixedBottomNavigation></FixedBottomNavigation>
    )
}