import {Card, Typography} from "@mui/material";
import {
    BusinessOutlined,
    ErrorOutlineOutlined,
    FitnessCenterOutlined,
    HomeOutlined,
    SchoolOutlined
} from "@mui/icons-material";
import React from "react";
import './SubscriptionAddressCard.css';
export default function SubscriptionAddressCardComponent(props) {
    const getIcon = (iconName) => {
        switch(iconName.toLowerCase()) {
            case 'home':
                return <HomeOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'office':
                return <BusinessOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'gym':
                return <FitnessCenterOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            case 'school':
                return <SchoolOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
            // Add more cases here if you have more icons
            default:
                return <ErrorOutlineOutlined sx={{ color: "#0049AF" }} className="trip-address-card-title-icon"/>;
        }
    };
    return (
        <div className="subscription-trip">
            <p className="trip-text">Trip {props.trip}:</p>
            <div className="trip-address-card-container">
                <Card sx={{p: 2, pl: 0, bgcolor: "transparent", boxShadow: 0}} className="trip-address-card">

                    <div className="trip-stepbar-container">
                        <div className="trip-stepbar-point"></div>
                        <div className="trip-stepbar-line"></div>
                        <div className="trip-stepbar-point"></div>
                    </div>
                    <div className="trip-address-card-data-container">
                        <div className="trip-address-card-data">
                            <div className="trip-address-card-title-container">
                                {getIcon(props.fromIcon)}
                                <Typography className="trip-address-card-title-text">
                                    {props.fromIcon}
                                </Typography>

                            </div>
                            <p className="trip-address-card-address">
                                {props.fromAddressLine1}
                                <br></br>
                                {props.fromAddressLine2}
                            </p>
                        </div>
                        <div className="trip-address-card-data">
                            <div className="trip-address-card-title-container">
                                {getIcon(props.toIcon)}
                                <Typography className="trip-address-card-title-text">
                                    {props.toIcon}
                                </Typography>

                            </div>
                            <p className="trip-address-card-address">
                                {props.toAddressLine1}
                                <br></br>
                                {props.toAddressLine2}
                            </p>
                        </div>
                    </div>

                </Card>
            </div>
        </div>
    )
}