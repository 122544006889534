import {Card, Typography} from "@mui/material";
import './PastRides.css'; // Import the CSS file
import { usePastRidesDetails } from "../../../Provider/PaymentDetails";
export default function PastRidesComponent(){
    const user_id = localStorage.getItem('user_id');
    const dropAddressType = localStorage.getItem('dropAddressType');
    const { cancelledRides, totalRides, completedRides, carryForward } = usePastRidesDetails(user_id, dropAddressType);
    console.log({carryForward});
    return(
        <div className="past-rides-container">
            <Card className="past-rides-card">
                <div className="past-rides-data-container">
                    <Typography>
                        Completed:
                    </Typography>
                    <Typography>
                        {completedRides}
                    </Typography>
                </div>
                <div className="past-rides-data-container">
                    <Typography>
                        Cancelled:
                    </Typography>
                    <Typography>
                        {cancelledRides}
                    </Typography>
                </div>
                <div className="past-rides-data-container">
                    <Typography>
                        Total Rides:
                    </Typography>
                    <Typography>
                        {totalRides}
                    </Typography>
                </div>
            </Card>
        </div>
    )
}