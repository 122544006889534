import {RidesHeaderComponent} from "../../Header/Header";
import {useLocation} from "react-router-dom";
import {Card, Typography} from "@mui/material";
import './Policy.css';

export default function PolicyComponent(){
    const location = useLocation();
    const ride = location.state.ride;
    const index = location.state.index;
    const returnTo = location.state.returnTo;
    let headerTag;
    if(returnTo === 'ride-details') {
        headerTag= <RidesHeaderComponent rides={"true"} link={`/ride-details/${ride.id}`} ride={ride} index={index} title={"Policy"}/>
    }
    else{
        headerTag= <RidesHeaderComponent rides={"true"} link={`/${returnTo}`} ride={ride} index={index} title={"Policy"}/>

    }
    console.log(ride)
    return(
        <div className="home-container">
            {headerTag}
            <div className="policy-container">
                <div className="policy-card-container">
                    <Card sx={{p: 2, borderRadius:3, border: '1px solid'}}>
                    <Typography variant="h6" gutterBottom>
                            Cancellation Policy
                        </Typography>
                        <Typography sx={{fontSize:16}}>
                            Customers will be given an option to cancel the ride for free if informed 2 hours in
                            advance.
                            <br></br>
                            <br></br>

                            This can happen only in 10% of the total rides booked by the customer.

                            <br></br>
                            <br></br>
                            For eg. (4 rides are cancellable if 40 trips are booked, 1 ride is cancellable if 5 trips
                            are booked).

                            <br></br>
                            <br></br>
                            All canceled rides will be added to next month’s subscription plan free of cost. In any
                            case, customers will have to bear the cost for any rides canceled above the prescribed 10%
                            rides.
                        </Typography>
                    </Card>
                </div>
                <div className="policy-card-container">
                    <Card sx={{p: 2, borderRadius:3, border: '1px solid'}}>
                        <Typography variant="h6" gutterBottom>
                            Reschedule Policy                        </Typography>
                        <Typography sx={{fontSize: 16}}>
                            Rescheduling is possible only if required slots are available.
                            <br></br>
                            <br></br>
                            You need to inform 2 hours in advance in case you want to change your pickup timings.
                            <br></br>
                            <br></br>
                            MyPickup reserves the right to accept or reject the reschedule request.
                            <br></br>
                            <br></br>
                            In such cases, cancelled rides will be carry-forwarded basis above cancellation policy.
                        </Typography>
                    </Card>
                </div>
            </div>
        </div>
    )
}