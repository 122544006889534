import {Button, TimePicker} from 'antd'
import HeaderComponent from "../../Header/Header";
import React, {useEffect, useState} from "react";
import './Subscription.css';
import dayjs from 'dayjs';
import SubscriptionAddressCardComponent from "../../SubscriptionAddressCard/SubscriptionAddressCard";
import {
    Card,
    Checkbox,
    Button as Button2,
    DialogTitle,
    IconButton,
    DialogContent,
    Typography,
    DialogActions, Dialog
} from "@mui/material";
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import SubscriptionService from "../../../Services/subscription.service";
import Swal from "sweetalert2";
import addressService from "../../../Services/address.service";
import { usePastRidesDetails } from '../../../Provider/PaymentDetails';
import TnCCard from "../../TnCCard/TnCCard";
 export default function SubscriptionComponent(){


     const [addresses, setAddresses] = useState([]);

     const [pickupAddress, setPickupAddress] = useState('Loading...');
     const [dropAddress, setDropAddress] = useState('Loading...');
     const [isAddressPresent, setIsAddressPresent] = useState(false);
     const [pickupAddressType, setPickupAddressType] = useState('Loading...');
     const [dropAddressType, setDropAddressType] = useState('Loading...');
     const [pricePerTrip, setPricePerTrip] = useState(0);
     const [pickupLat, setPickupLat] = useState(0);
     const [pickupLong, setPickupLong] = useState(0);
     const [dropLat, setDropLat] = useState(0);
     const [dropLong, setDropLong] = useState(0);


     useEffect(() => {
         // Call the getPricePerTrip function when the component mounts
         SubscriptionService.getPricePerTrip()
             .then(data => {
                 // Update the pricePerTrip state variable with the returned value
                 setPricePerTrip(data.price_per_trip);
             })
             .catch(error => {
                 console.error(error);
             });
     }, []);


     useEffect(() => {
         const fetchAddresses = async () => {
             try {
                 let address;
                 const cachedAddresses = localStorage.getItem('addresses');
                 const addressTimestamp = localStorage.getItem('addressTimestamp');

                 if (cachedAddresses && addressTimestamp) {
                     const currentTime = new Date().getTime();
                     const timeDifference = (currentTime - addressTimestamp) / 1000 / 60; // Convert to minutes

                     if (timeDifference < 1) {
                         // If the cached address is less than 1 minute old, use it
                         address = JSON.parse(cachedAddresses);
                     }
                 }

                 if (!address) {
                     // If there's no cached address or it's more than 1 minute old, fetch a new one
                     address = await addressService.getAddress();

                     // Store the new address and the current addressTimestamp in local storage
                     localStorage.setItem('addresses', JSON.stringify(address));
                     localStorage.setItem('addressTimestamp', new Date().getTime().toString());
                 }

                 if(address.length>0){
                     setAddresses(address);
                     setPickupAddress(address.find(item => item.address_type === 'Home')?.address || 'Address not found');
                     setDropAddress(address.find(item => !(item.address_type === 'Home'))?.address || 'Address not found');
                     setPickupAddressType(address.find(item => item.address_type === 'Home')?.address_type || 'Address not found');
                     setDropAddressType(address.find(item => !(item.address_type === 'Home'))?.address_type || 'Address not found');
                     setPickupLat(address.find(item => item.address_type === 'Home')?.latitude || 0);
                     setPickupLong(address.find(item => item.address_type === 'Home')?.longitude || 0);
                     setDropLat(address.find(item => !(item.address_type === 'Home'))?.latitude || 0);
                     setDropLong(address.find(item => !(item.address_type === 'Home'))?.longitude || 0);

                     setIsAddressPresent(true);
                 }
                 else{
                     setPickupAddress('No Address Found');
                     setDropAddress('No Address Found');
                     setPickupAddressType('Not Found');
                     setDropAddressType('Not Found');
                     setIsAddressPresent(false);
                 }
                 if(address.length===1){
                     setIsAddressPresent(false)
                 }
             } catch (error) {
                 console.error('Error:', error);
             }
         };

         fetchAddresses();
         // Refresh the address every 1 minute
         const intervalId = setInterval(fetchAddresses, 60 * 1000);

         // Clear the interval when the component unmounts
         return () => clearInterval(intervalId);
     }, []);
     console.log(addresses)

     localStorage.setItem('dropAddressType',dropAddressType);






     const [fromAddressLine1, fromAddressLine2] = pickupAddress.split(',');
     const [toAddressLine1, toAddressLine2] = dropAddress.split(',');



     const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
     const [checkedStatus, setCheckedStatus] = useState(
         daysOfWeek.reduce((acc, day) => ({ ...acc, [day]: { trip1: false, trip2: false } }), {})
     );
     const [trip1Time, setTrip1Time] = useState(null);
     const [trip2Time, setTrip2Time] = useState(null);
     let dates = [];

     // Find the next Monday
     let today = new Date();
     let nextMonday;

     //Testing Only to Insert Data for Current Week
     // if (today.getDay() === 4) {
     //     // If today is tuesday, subtract 1 day to get the current Monday
     //     nextMonday = new Date(today.getTime());
     //     nextMonday.setDate(today.getDate() -3 );

// Check if today is Monday
     if (today.getDay() === 1) {
         // If today is Monday, add 7 days to get the next Monday
         nextMonday = new Date(today.getTime());
         nextMonday.setDate(today.getDate() + 7);
     } else {
         // If today is not Monday, find the next Monday
         nextMonday = new Date(today.getTime());
         nextMonday.setDate(today.getDate() + ((1 + 7 - today.getDay()) % 7));
     }

     for (let i = 0; i <= 5; i++) {
         let dateObj = new Date(nextMonday.getTime());
         dateObj.setDate(dateObj.getDate() + i);

         let day = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
         let date = String(dateObj.getDate()).padStart(2, '0'); // Get the day of the month and pad it with zeros
         let year_month = String(dateObj.getFullYear()+'-'+String(dateObj.getMonth() + 1).padStart(2, '0'));
         dates.push({
             id: i,
             date: date,
             day: day,
             year_month: year_month
         });
     }


// Handle checkbox change
     const handleCheckboxChange = (day, trip) => {
         setCheckedStatus(prevStatus => {
             const newStatus = {
                 ...prevStatus,
                 [day]: { ...prevStatus[day], [trip]: !prevStatus[day][trip] }
             };

             // Save the new state to local storage
             localStorage.setItem('checkedStatus', JSON.stringify(newStatus));

             return newStatus;
         });
     };

// Restore the state from local storage when the component mounts
     useEffect(() => {
         const savedCheckedStatus = localStorage.getItem('checkedStatus');
         if (savedCheckedStatus) {
             setCheckedStatus(JSON.parse(savedCheckedStatus));
         }
     }, []);
     const handleSubmit = () => {
         console.log(checkedStatus);
         if ((trip1Selected && !trip1Time) || (trip2Selected && !trip2Time)) {
             return; // Prevent the form from being submitted
         }
         handleClose();

         // Send the data to the backend
         console.log(getRideDetails());
         console.log(checkedStatus);

         Swal.fire({
             title: 'Processing...',
             didOpen: () => {
                 Swal.showLoading();
             },
             allowOutsideClick: () => !Swal.isLoading()
         });

         SubscriptionService.addRides({
             user_id: +localStorage.getItem('user_id'),
             subscription_plan: getCurrentWeek()+"_"+dropAddressType,
             ride_details: getRideDetails()
         }).then(data => {
             console.log(data);
         }).catch(error => {
             console.error(error);
         }).finally(() => {
             Swal.close();
             Swal.fire({
                 icon: 'success',
                 title: 'Request Added Successfully',
                 text: 'Please visit after an hour for the payment',
                 showConfirmButton: false,
                 timer: 2000
             }).then(() => {
                 window.location.reload('/home');
             });
         });
     }
// Handle time change
     const handleTimeChange = (trip, time) => {
         const formattedTime = time ? time.format('HH:mm:ss') : null;
         if (trip === 'trip1') {
             setTrip1Time(formattedTime);
             // Save the trip1Time to local storage
             localStorage.setItem('trip1Time', JSON.stringify(formattedTime));
         } else if (trip === 'trip2') {
             setTrip2Time(formattedTime);
             // Save the trip2Time to local storage
             localStorage.setItem('trip2Time', JSON.stringify(formattedTime));
         }
         console.log({formattedTime})
     };

// Restore the trip1Time and trip2Time from local storage when the component mounts
     useEffect(() => {
         const savedTrip1Time = JSON.parse(localStorage.getItem('trip1Time'));
         const savedTrip2Time = JSON.parse(localStorage.getItem('trip2Time'));
         if (savedTrip1Time) {
             setTrip1Time(savedTrip1Time);
         }
         if (savedTrip2Time) {
             setTrip2Time(savedTrip2Time);
         }
         console.log({savedTrip1Time,savedTrip2Time})
     }, []);
     const getRideDetails = () => dates.map(({id, date, day, year_month}) => {
         // Create an array that only includes the trips where the corresponding checkedStatus is true
         const trips = [];
         if (checkedStatus[day].trip1) {
             trips.push(trip1Time);
         }
         if (checkedStatus[day].trip2) {
             trips.push(trip2Time);
         }

         return trips.map((time, index) => {
             const datetime =`${year_month}-${date} ${time}.000`;
             const isTrip1 = index === 0;
             const shouldPickup = (isTrip1 && checkedStatus[day].trip1);
             return {
                 "pickup_address": shouldPickup ? pickupAddress : dropAddress,
                 "pickup_address_type": shouldPickup ? pickupAddressType : dropAddressType,
                 "drop_address": shouldPickup ? dropAddress : pickupAddress,
                 "drop_address_type": shouldPickup ? dropAddressType : pickupAddressType,
                 "datetime": datetime,
                 day,
                 "pickup_lat": shouldPickup ? pickupLat : dropLat,
                 "pickup_long": shouldPickup ? pickupLong : dropLong,
                 "drop_lat": shouldPickup ? dropLat : pickupLat,
                 "drop_long": shouldPickup ? dropLong : pickupLong,
             };
         });
     }).flat();

     function getCurrentWeek() {
         // Get the current date
         const now = new Date();
         // Get the first day of the year
         const start = new Date(now.getFullYear(), 0, 0);
         // Calculate the difference in milliseconds
         const diff = now - start;
         // Convert the difference to days
         const oneDay = 1000 * 60 * 60 * 24;
         const day = Math.floor(diff / oneDay);
         // Get the current week number
         const week = Math.ceil(day / 7);
         // Check if the week number is even or odd
         return week % 2 === 0 ? 'week_a' : 'week_b';
     }
     const [open, setOpen] = React.useState(false);

     const trip1Selected = Object.values(checkedStatus).some(status => status.trip1);
     const trip2Selected = Object.values(checkedStatus).some(status => status.trip2);
     const handleClickOpen = () => {
         // Check if either trip1Time or trip2Time is null
         if ((trip1Selected && !trip1Time) || (trip2Selected && !trip2Time)) {
             console.log("entered error zone")
             Swal.fire({
                 icon: 'error',
                 title: 'Please select a time for trips',
                 showConfirmButton: false,
                 timer: 1500
             });
             return; // Prevent the form from being submitted
         }
         if (!isAddressPresent) {
             Swal.fire({
                 icon: 'error',
                 title: 'No Address found in profile',
                 text: 'Contact support to add address',
                 showConfirmButton: false,
                 timer: 1500
             });
             return; // Prevent the form from being submitted
         }
         setOpen(true);

     };
     const handleClose = () => {
         setOpen(false);
     };
     const getTotalRidesPlanned =()=>{
            let totalRides = 0;
            for (const element of dates) {
                let day = element.day;
                if (checkedStatus[day].trip1) {
                    totalRides++;
                }
                if (checkedStatus[day].trip2) {
                    totalRides++;
                }
            }
            return totalRides;
     }
     //Fetch the cancelled rides of last week
     const user_id= localStorage.getItem('user_id');

     const { carryForward } = usePastRidesDetails(user_id, dropAddressType,getTotalRidesPlanned());
     console.log({carryForward});

        const [subscriptionCardWarningText, setSubscriptionCardWarningText] = useState('');

     const [ridesPresent,setRidesPresent]=useState(false);
     useEffect(() => {
         let temp = JSON.parse(localStorage.getItem('areNextWeekRidesPresent'));
         setRidesPresent(temp);
         setSubscriptionCardWarningText("Subscription is already Recorded.");
     }, []);

     console.log({ridesPresent});

     const [isButtonDisabled, setIsButtonDisabled] = useState(false);

     useEffect(() => {
         const date = new Date();
         const day = date.getDay();
         const hours = date.getHours();
         const minutes = date.getMinutes();
         console.log({hours,minutes,day})

         // Convert the current time to IST (Indian Standard Time is UTC+5:30)
         // const offsetIST = 5.5;
         // const utc = hours + minutes / 60;
         // const timeIST = utc + offsetIST;
         // console.log({timeIST})

         // Check if today is Sunday and the time is between 20:00 and 22:00 IST

         if (day === 0 && hours >= 20) {
             setIsButtonDisabled(true);
             setSubscriptionCardWarningText("Subscription window for the next week is closed");
         }
         else if (day===6 || day===0)
         {
             setIsButtonDisabled(false);
         }
         else {
             setIsButtonDisabled(true);
             setSubscriptionCardWarningText("Subscription window for the next week will open on Saturday");
         }

     }, []);
     console.log({isButtonDisabled})



     const [activeButton, setActiveButton] = useState('next');
     const [key, setKey] = useState(0); // Add a key state


     const [currentDate, setCurrentDate] = useState('');
     const [currentWeek, setCurrentWeek] = useState(0);
     const [toDate, setToDate] = useState('');



     useEffect(() => {
         const date = new Date();

         // Check if today is Sunday
         if (date.getDay() === 0) {
             // If today is Sunday, set the next day (tomorrow) as Monday
             date.setDate(date.getDate() + 1);
         } else {
             // If today is not Sunday, find the next Monday
             date.setDate(date.getDate() + ((1 + 7 - date.getDay()) % 7));
         }

         const startOfWeek = new Date(date);
         startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);
         const startDate= startOfWeek.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });


         // Check if today is Sunday
         if (date.getDay() === 0) {
             // If today is Sunday, subtract 1 from the week number
             date.setDate(date.getDate() - 7);
         }
         const start = new Date(date.getFullYear(), 0, 0);
         const diff = (date - start) + ((start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000);
         const oneDay = 1000 * 60 * 60 * 24;
         const day = Math.floor(diff / oneDay);
         const currentWeek = Math.ceil(day / 7);
         setCurrentWeek(currentWeek);
         startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 6);
         const endDate= startOfWeek.toLocaleDateString('en-GB', { day: '2-digit', month: 'short' });

         setCurrentDate(startDate);

         setToDate(endDate);
     }, []);
     console.log( {currentWeek,currentDate ,toDate})



     console.log({trip1Time,trip2Time})
     return(
         <div className="home-container">

             <Dialog
                 onClose={handleClose}
                 aria-labelledby="customized-dialog-title"
                 open={open}
                 fullWidth={true}
                 maxWidth={'lg'}
             >
                 <DialogTitle sx={{m: 0, p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                              id="customized-dialog-title">
                     Payment Details
                     <IconButton
                         aria-label="close"
                         onClick={handleClose}
                         sx={{
                             color: (theme) => theme.palette.grey[500],
                         }}
                     >
                         <CloseIcon/>
                     </IconButton>
                 </DialogTitle>
                 <DialogContent dividers>
                     <Card sx={{p: 2}}>
                         <div className="payment-page-data-container">
                             <Typography>
                                 Weekly Total Rides Planned:
                             </Typography>
                             <Typography>
                                 {getTotalRidesPlanned()}
                             </Typography>
                         </div>
                         {/*<div className="payment-page-data-container">*/}
                         {/*    <Typography>*/}
                         {/*        Carry Forward:*/}
                         {/*    </Typography>*/}
                         {/*    <Typography>*/}
                         {/*        {carryForward}*/}
                         {/*    </Typography>*/}
                         {/*</div>*/}
                         {/*<div className="payment-page-data-container">*/}
                         {/*    <Typography>*/}
                         {/*        Total Rides Charged:*/}
                         {/*    </Typography>*/}
                         {/*    <Typography>*/}
                         {/*        {getTotalRidesPlanned() - carryForward}*/}
                         {/*    </Typography>*/}
                         {/*</div>*/}
                         <div className="payment-page-data-container">
                             <Typography>
                                 Per Trip Cost:
                             </Typography>
                             <Typography>
                                 {pricePerTrip || "Loading..."}
                             </Typography>
                         </div>
                         {/*<div className="payment-page-data-container">*/}
                         {/*    <Typography>*/}
                         {/*        Subscription Cost:*/}
                         {/*    </Typography>*/}
                         {/*    <Typography>*/}
                         {/*        {pricePerTrip * (getTotalRidesPlanned() - carryForward)}*/}
                         {/*    </Typography>*/}
                         {/*</div>*/}

                     </Card>

                     <TnCCard/>


                 </DialogContent>
                 <DialogActions>
                     <Button2 variant="outlined" onClick={handleSubmit}>
                         Submit Request
                     </Button2>
                 </DialogActions>
             </Dialog>
             <HeaderComponent/>
             <div className="date-view-subscription">

                 <Typography sx={{color: '#132961', fontSize: '16px'}} className="date-view-data-subscription">
                     Date: {currentDate} - {toDate}
                 </Typography>

             </div>

             <div className="week-tab"> {/* Add the key here */}
                 <div className="week-button-conatiner" key={key}>
                     <Button
                         shape="round"
                         type="primary"
                         disabled={true}
                         className={`week-button ${activeButton === 'current' ? 'active' : ''}`}
                         onClick={() => {
                             setActiveButton('current');
                             setKey(prevKey => prevKey + 1); // Change the key every time a button is clicked
                         }}
                     >
                         Current Week
                     </Button>
                     <Button
                         shape="round"
                         type="primary"
                         className={`week-button ${activeButton === 'next' ? 'active' : ''}`}
                         onClick={() => {
                             setActiveButton('next');
                             setKey(prevKey => prevKey + 1); // Change the key every time a button is clicked
                         }}
                     >
                         Next week
                     </Button>
                 </div>
             </div>
             <div className="subscription-container">
                 <div className="subscription-trip-container">
                     <SubscriptionAddressCardComponent
                         trip={1}
                         fromIcon={pickupAddressType}
                         toIcon={dropAddressType}
                         fromAddressLine1={fromAddressLine1}
                         fromAddressLine2={fromAddressLine2}
                         toAddressLine1={toAddressLine1}
                         toAddressLine2={toAddressLine2}
                     />
                     <SubscriptionAddressCardComponent
                         trip={2}
                         fromIcon={dropAddressType}
                         toIcon={pickupAddressType}
                         fromAddressLine1={toAddressLine1}
                         fromAddressLine2={toAddressLine2}
                         toAddressLine1={fromAddressLine1}
                         toAddressLine2={fromAddressLine2}
                     />

                 </div>
                 <div className="subscription-rules-info-data">
                     <Card sx={{p:2,mb:2}}>
                         <Typography sx={{fontSize:14,textAlign:'center'}}>
                             Subscription window opens on every Saturday to Sunday (7PM)
                         </Typography>

                     </Card>
                 </div>
                 <Card sx={{p: 2}} className="time-slot-container">
                     {(ridesPresent || isButtonDisabled) && (
                         <Typography color="error" style={{textAlign: "center"}}>
                             {subscriptionCardWarningText}
                         </Typography>
                     )}
                     <div className="time-slot-day-container">
                         <div className="day-container">
                             <p className="checkbox-container weekday-label">
                                 Time Slot:
                             </p>
                             <div className="checkbox-container time-slot-day-time-timepicker">
                                 <TimePicker inputReadOnly={true} minuteStep={5}
                                             // value={trip1Time ? moment(trip1Time, 'HH:mm:ss') : null}
                                             value={trip1Time ? dayjs(trip1Time, 'HH:mm:ss') : undefined}
                                             
                                             onChange={(time) => handleTimeChange('trip1', time)} format='hh:mm a'
                                             placeholder="Time"/>
                             </div>
                             <div className="checkbox-container time-slot-day-time-timepicker">
                                 <TimePicker inputReadOnly={true} minuteStep={5}
                                             // value={trip2Time ? moment(trip2Time, 'HH:mm:ss') : null}
                                             value={trip2Time ? dayjs(trip2Time, 'HH:mm:ss') : undefined}
                                             
                                             onChange={(time) => handleTimeChange('trip2', time)} format='hh:mm a'
                                             placeholder="Time"/>
                             </div>

                         </div>


                         {daysOfWeek.map(day => (
                             <div key={day} className="day-container">
                                 <div className="checkbox-container weekday-label">
                                     <p className="weekday-label-text">{day}</p>
                                 </div>

                                 <div className="checkbox-container2">
                                     <label>
                                         <Checkbox
                                             icon={<RadioButtonUncheckedIcon/>}
                                             checkedIcon={<CheckCircleIcon/>}
                                             checked={checkedStatus[day].trip1}
                                             onChange={() => handleCheckboxChange(day, 'trip1')}
                                         />
                                         Trip 1
                                     </label>
                                 </div>
                                 <div className="checkbox-container2">
                                     <label>
                                         <Checkbox
                                             icon={<RadioButtonUncheckedIcon/>}
                                             checkedIcon={<CheckCircleIcon/>}
                                             checked={checkedStatus[day].trip2}
                                             onChange={() => handleCheckboxChange(day, 'trip2')}
                                         />
                                         Trip 2
                                     </label>
                                 </div>
                             </div>
                         ))}
                     </div>
                 </Card>
                 <div className="subscription-button-container">
                     <Button2 
                         disabled={ridesPresent || isButtonDisabled} 
                         variant="contained" fullWidth={true}
                              className="subscription-button" onClick={handleClickOpen}>Proceed</Button2>
                 </div>
             </div>

         </div>
     )
 }
