import logo from "../../Assets/logo.png";
import React from "react";
import './Header.css'
import {ArrowBackOutlined} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {IconButton} from "@mui/material";

export default function HeaderComponent(){
    let userDetails = localStorage.getItem('userDetails');
    if (userDetails && userDetails !== '') {
        try {
            userDetails = JSON.parse(userDetails);
        } catch (error) {
            console.error('Error parsing userDetails:', error);
            userDetails = null;
        }
    } else {
        userDetails = null; // or a default value
    }
    const fullName = userDetails?.name;
    const firstName = fullName?.split(' ')[0];

    return (
        <div className="home-welcome-text">
            <div className="user-name">Hello {firstName}</div>
            <div>
                <img src={logo} alt="home-logo" className="home-logo"/>
            </div>
        </div>
    )
}
export function RidesHeaderComponent(props){
    const navigate = useNavigate();

    return(
        <div className="home-welcome-text" >

            <IconButton aria-label="Back" className="back-arrow-icon" onClick={() => navigate(props.link, { state: {ride: props.ride, index: props.index}  } ) }  >                <ArrowBackOutlined />
            </IconButton>

            <div className="rides-detail-text">{props.title}</div>
            <div>
                <img src={logo} alt="home-logo" className="home-logo"/>
            </div>
        </div>
    )
}
