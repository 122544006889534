import { useCallback, useEffect, useState } from 'react';
import SubscriptionService from '../Services/subscription.service';

export const usePastRidesDetails = (user_id, dropAddressType,nextWeekRideCount) => {
    const [cancelledRides, setCancelledRides] = useState(0);
    const [totalRides, setTotalRides] = useState(0);
    const [completedRides, setCompletedRides] = useState(0);
    const [carryForward, setCarryForward] = useState(0);


    const calculateCarryForward = useCallback(() => {
        if(cancelledRides===0 && totalRides===0){
            return 0;
        }
        let carryForward = 0;
        if(totalRides>=8){
            carryForward = Math.min(2,cancelledRides);
        }
        else if(totalRides>=4){
            carryForward = Math.min(1,cancelledRides);
        }
        console.log(carryForward)
        return carryForward;
        // return 0;
    }, [cancelledRides, totalRides]);

    useEffect(() => {
        if (dropAddressType !== 'Loading...') {
            SubscriptionService.getRidesCountByUserID(user_id, dropAddressType)
                .then(data => {
                    if(data==='SERVER ERROR'){
                        setCancelledRides(0);
                        setTotalRides(0);
                        setCompletedRides(0);
                    }
                    else{
                        console.log("Carry Forward API Called")
                        setCancelledRides(data.cancelled_rides_count);
                        setTotalRides(data.total_rides_count);
                        setCompletedRides(data.completed_rides_count);
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [user_id, dropAddressType]);

    useEffect(() => {
        setCarryForward(calculateCarryForward());
    }, [calculateCarryForward]);

    console.log({ cancelledRides, totalRides, completedRides, carryForward });
    return { cancelledRides, totalRides, completedRides, carryForward };
};
