import React, { useState, useRef } from 'react';
import { Form, Input, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Logo from '../../../Assets/logo.png';
import Landing from '../../../Assets/landing.png';
import './Login.css';
import AuthService from "../../../Services/auth.service";
import Swal from "sweetalert2";

const Login2 = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpGenerated, setOtpGenerated] = useState(false); // Add this line
    const [otpDigits, setOtpDigits] = useState(Array(6).fill(""));

    const navigate = useNavigate();
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    if (localStorage.getItem("access_token")) {
        window.location.href = "/home";
    }

    function handleOtpChange(index, value) {
        const newOtpDigits = [...otpDigits];
        newOtpDigits[index] = value;
        setOtpDigits(newOtpDigits);
    }

    const handleRequestOTP = async (e) => {
        // Handle the OTP request here
        console.log(`OTP requested for phone number: ${phoneNumber}`);
        // Add this line
        e.preventDefault();
        try {
            await AuthService.generateOTP(phoneNumber).then(
                (data) => {
                    console.log(data);
                    Swal.fire({
                        title: 'OTP Sent!',
                        // text: 'Please check your phone for OTP. Use the OTP- ' + data.otp + ' to login',
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        html: 'Enter the OTP given below- <b>' + data.otp+'</b>'
                    });
                    setOtpGenerated(true);

                    // Split the OTP into an array of digits and set the otpDigits state
                    setOtpDigits(data.otp.split(''));
                },
                (error) => {
                    console.log(error);
                    Swal.fire({
                        title: 'User Not Found',
                        text: 'Please contact support to register yourself',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                }
            );
        } catch (err) {
            console.log(err);
        }
    };

    const handleOTPVerification = async (e) => {
        e.preventDefault();

        let otp2 = otpDigits.join('');
        try {
            await AuthService.verifyOTP(phoneNumber, otp2).then(
                (data) => {
                    localStorage.setItem("access_token", data.access_token);
                    localStorage.setItem("phone_number", phoneNumber);
                    console.log(data.id);
                    navigate("/home");
                    window.location.reload();
                },
                (error) => {
                    console.log(error);
                }
            );
        } catch (err) {
            console.log(err);
        }
        // Handle the OTP verification here
        console.log(`OTP verification for OTP: ${otpDigits}`);
    };

    const inputRefs = useRef(
        Array(6)
            .fill(null)
            .map(() => React.createRef())
    );

    const handleInputFocus = (index) => {
        if (index < otpDigits.length - 1) {
            inputRefs.current[index + 1].current.focus();
        }
    };


    return (
        <div className="main-container">
            <img src={Logo} alt="logo" className="logo" />
            <p className="welcome-text">Welcome</p>
            <p className="instruction-text">Login with mobile number to continue</p>
            {!otpGenerated && ( // Add this line
                <Form className="form">
                    <Form.Item label="Phone Number">
                        <Input type="tel" placeholder="Enter Mobile No." value={phoneNumber}
                            onChange={handlePhoneNumberChange} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleRequestOTP}>Request OTP</Button>
                    </Form.Item>
                </Form>
            )}
            {otpGenerated && ( // Add this line
                <Form className="form" onSubmit={handleOTPVerification}>
                    <Form.Item label="OTP">
                        {/* <InputOTP
                            style={{width:"10px"}}
                            inputType="numeric"
                            value={otp}
                            onChange={(otp) => setOtp(otp)} // Join the array of digits into a string
                        /> */}
                        <div style={{display:"flex"}}>
                            {otpDigits.map((value, index) => (
                                <div key={index} style={{width:"1/10",margin:"2px"}}>
                                    <Input
                                        type="number"
                                        placeholder={"0"}
                                        style={{height:"12", width:"10", textAlign:"center"}}
                                        maxLength={1}
                                        value={value}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        onInput={() => handleInputFocus(index)}
                                        ref={inputRefs.current[index]}
                                    />
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={handleOTPVerification}>Verify OTP</Button>
                    </Form.Item>
                </Form>
            )}
            <img src={Landing} alt="landing" className="bottomimg" />
        </div>
    );
};

export default Login2;
